<template>
  <template v-for="(entry, index) in pageContentEntries" :key="entry.id">
    <NuxtErrorBoundary @error="(e) => console.error(e)">
      <component
        :is="getComponent(entry.typeHandle as BlockTypeHandle)"
        :data-testid="entry.typeHandle"
        :block-id="entry.id"
        class="section page__generic mt-md md:mt-xl"
        :block-data="entry"
        :render-index="index"
        :is-header="isHeader(entry, index)"
      />
      <template #error>
        <p class="text-status-danger-base">
          there is an error in the component
        </p>
      </template>
    </NuxtErrorBoundary>
  </template>
</template>

<script setup lang="ts">
import {
  pageBlocksImports,
  defaultBlockImport,
} from '~~/src/components/pageContent/vdv/pageBlocksImportMap';
import {
  type BaseBlockType,
  BlockTypeHandle,
} from '~~/src/components/block/types';

const props = defineProps({
  pageContentEntries: {
    type: Array<BaseBlockType>,
    required: true,
  },
  isRoot: {
    type: Boolean,
    required: false,
    default: false,
  },
  noHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
});

function getComponent(typeHandle: BlockTypeHandle) {
  return pageBlocksImports[typeHandle] ?? defaultBlockImport;
}

const canBeHeaderTypeHandles = [
  BlockTypeHandle.CONTENT_HEADER,
  BlockTypeHandle.AREA_INTRO_TOP_BANNER,
  BlockTypeHandle.IMAGE_GALLERY,
  BlockTypeHandle.IMAGE_GALLERY_EXTENDED,
  BlockTypeHandle.STYLED_SECTION,
];

function isHeader(block: BaseBlockType, index: number) {
  if (props.noHeader) return false;
  if (index === 0 && canBeHeaderTypeHandles.includes(block.typeHandle)) {
    return true;
  } else {
    return false;
  }
}
</script>
